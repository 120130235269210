import styled from "styled-components";
import { BaseButtonProps } from "./types";

// const scaleVariants = {
//   [scales.LG]: "48px",
//   [scales.MD]: "40px",
//   [scales.SM]: "32px",
//   [scales.XS]: "24px",
// };

const IconButton = styled("button")<BaseButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0;
`;

export default IconButton;
