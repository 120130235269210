import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.XS]: {
    height: "20px",
    borderRadius: "4px",
    fontSize: "12px",
    padding: "4px 8px",
  },
  [scales.SM]: {
    height: "40px",
    borderRadius: "4px",
    padding: "10px 12px",
    fontSize: "14px",
    "@media screen and (min-width: 1024px)": { fontSize: "15px" },
  },
  [scales.MD]: {
    height: "48px",
    padding: "12px 20px",
    fontSize: "15px",
    "@media screen and (min-width: 1024px)": { fontSize: "16px" },
  },
  [scales.LG]: {
    height: "56px",
    borderRadius: "8px",
    padding: "16px 24px",
    fontSize: "16px",
    "@media screen and (min-width: 1024px)": { fontSize: "18px" },
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    background: "linear-gradient(270deg, #5BB3D9 0%, #444259 100%)",
    color: "#fff",
    ":hover": {
      boxShadow: "0px 4px 24px rgba(90, 170, 206, 0.2)",
    },
    "&.button--loading:disabled": {
      opacity: 1,
      color: "transparent",
      background: "linear-gradient(270deg, #5BB3D940 0%, #44425940 100%)",
    },
  },
  [variants.SECONDARY]: {
    backgroundColor: "primary",
    color: "#fff",
    ":hover": {
      boxShadow: "0px 4px 24px rgba(228, 139, 120, 0.6)",
    },
    ":active": {
      boxShadow: "0px 4px 24px rgba(228, 139, 120, 0.6)",
    },
    "&.button--loading:disabled": {
      color: "transparent",
    },
  },
  [variants.BLUE]: {
    background: "linear-gradient(270deg, #5BB3D9 0%, #5BB2D8 100%)",
    color: "#fff",
    fontWeight: 400,
    "&.button--loading:disabled": {
      color: "transparent",
    },
  },
  [variants.OUTLINE]: {
    backgroundColor: "transparent",
    color: "primary",
    border: "1px solid",
  },
  [variants.GHOST]: {
    backgroundColor: "transparent",
    color: "text",
    border: "1px solid transparent",
    borderColor: "borderColor",
  },
  [variants.TAB]: {
    backgroundColor: "transparent",
    color: "text",
    '&[data-state="active"]': {
      color: "white",
      backgroundColor: "primary",
      boxShadow: "btnActive",
    },
    "&.button--loading:disabled": {
      color: "transparent",
    },
  },
};
