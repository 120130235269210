import * as React from "react";
import type { SVGProps } from "react";
const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.5 3.5h-1v-1h1v1Zm0 4h-1v-3h1v3ZM5.005 10C7.765 10 10 7.76 10 5S7.765 0 5.005 0A5.003 5.003 0 0 0 0 5c0 2.76 2.24 5 5.005 5ZM5 1c2.21 0 4 1.79 4 4S7.21 9 5 9 1 7.21 1 5s1.79-4 4-4Z"
    />
  </svg>
);
export default SvgInfoIcon;
