import * as React from "react";
import type { SVGProps } from "react";
const SvgClipboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#787889"
      d="M11.25 2.25H3a.75.75 0 0 0-.75.75v8.25c0 .414.336.75.75.75h8.25a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Zm0 9H3V3h8.25v8.25Z"
    />
    <path
      fill="#787889"
      d="M.75.75H9v.75h.75V.75A.75.75 0 0 0 9 0H.75A.75.75 0 0 0 0 .75V9c0 .414.336.75.75.75h.75V9H.75V.75Z"
    />
  </svg>
);
export default SvgClipboardIcon;
