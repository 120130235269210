import { configureChains, createConfig } from "wagmi";
import {
  mainnet,
  bsc,
  polygon,
  optimism,
  gnosis,
  fantom,
  zkSync,
  polygonZkEvm,
  base,
  arbitrum,
  avalanche,
  zora,
  aurora,
} from "wagmi/chains";
import {
  walletConnectWallet,
  metaMaskWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";

import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import sahalWallet from "utils/sahalWallet";
import { REACT_APP_PROJECT_ID, REACT_APP_RPC_URL } from "config";

// const rpcMap: { [key: string]: string } = {
//   1: "https://eth.llamarpc.com",
//   56: "https://rpc.ankr.com/bsc", // https://bsc-dataseed1.defibit.io, https://nd-294-466-766.p2pify.com/a74968ea5fd8c167dcd9e42e17d41
//   97: "https://data-seed-prebsc-1-s1.binance.org:8545",
// };

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet,
    bsc,

    polygon,
    optimism,
    gnosis,
    fantom,
    zkSync,
    polygonZkEvm,
    base,
    arbitrum,
    avalanche,
    zora,
    aurora,
  ],
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: REACT_APP_RPC_URL! }),
    }),
    publicProvider(),
  ]
);
const projectId = REACT_APP_PROJECT_ID!;

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [sahalWallet({ projectId, chains })],
  },
  {
    groupName: "Other",
    wallets: [
      metaMaskWallet({
        projectId,
        chains,
      }),

      walletConnectWallet({
        projectId,
        chains,
      }),
    ],
  },
]);

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
