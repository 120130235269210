import * as React from "react";
import type { SVGProps } from "react";
const SvgDashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title>bridge</title>{" "}
      <path d="M15.915 8.358c-4.269 0-7.994 2.525-9.982 6.279h-3.51v5.812h7.020c0.198-3.703 3.014-6.642 6.471-6.642s6.273 2.939 6.471 6.642h7.020v-5.812h-3.51c-1.987-3.754-5.713-6.279-9.982-6.279v0zM2.423 21.484v9.375h7.028v-9.375h-7.028zM22.379 21.484v9.375h7.028v-9.375h-7.028z"></path>{" "}
    </g>
  </svg>
);
export default SvgDashboardIcon;
