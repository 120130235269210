import * as React from "react";
import type { SVGProps } from "react";
const SvgSafetyCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <mask
      id="safety-check-icon_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#safety-check-icon_svg__a)">
      <path
        fill="currentColor"
        d="m12 23-7.2-5.4a1.913 1.913 0 0 1-.587-.7A1.982 1.982 0 0 1 4 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12a1.99 1.99 0 0 1-.8 1.6L12 23Zm0-2.5 6-4.5V4H6v12l6 4.5ZM10.95 15l5.65-5.65-1.4-1.45-4.25 4.25-2.1-2.1-1.45 1.4L10.95 15Z"
      />
    </g>
  </svg>
);
export default SvgSafetyCheckIcon;
