import { Link as RouterLink, useLocation } from "react-router-dom";
import styled from "styled-components";
// import {
//   Collapsible,
//   CollapsibleContent,
//   CollapsibleTrigger,
// } from "components/collapsible";
import clsx from "clsx";
// import { Text } from "components/Text";
import {
  BridgeIcon,
  DashboardIcon,
  DocsIcon,
  ToolsIcon,
  TrophyIcon,
  TutorialsIcon,
} from "components/Icons";

type SidebarItem = {
  name: string;
  icon: React.ReactNode;
  path?: string;
  disabled?: boolean;
  children?: SidebarItem[];
};

const sidebarConfig: SidebarItem[] = [
  {
    name: "Homepage",
    icon: <DashboardIcon />,
    path: "/",
    disabled: false,
  },
  {
    name: "Vaults",
    icon: <TrophyIcon />,
    path: "/vaults",
  },
  {
    name: "Bridge",
    icon: <BridgeIcon />,
    path: "/bridge",
  },
  {
    name: "Tools",
    icon: <ToolsIcon />,
    path: "/tools",
  },
  {
    name: "Tutorials",
    icon: <TutorialsIcon />,
    path: "/tutorials",
  },
];

const isActivePath = (currentPath: string, path: string) => {
  if (path === "/") {
    return currentPath === path;
  }

  return currentPath.startsWith(path);
};

function SidebarMenu() {
  const { pathname } = useLocation();

  const getMenuItem = (entry: any, isNested = false) => (
    <MenuItem key={entry.path}>
      <MenuLink
        as={RouterLink}
        to={entry.path}
        className={clsx({
          active: isActivePath(pathname, entry.path),
          nested: isNested,
          "disabled-link": entry.disabled,
        })}
      >
        <span style={{ fontSize: "24px" }}>{entry.icon}</span>

        <span>{entry.name}</span>
      </MenuLink>
    </MenuItem>
  );

  return (
    <MenuWrapper>
      <MenuList>
        {sidebarConfig.map((entry, idx) => {
          if (!entry.children) {
            return getMenuItem(entry);
          }
          return (
            // <Collapsible key={idx} defaultOpen={true} asChild>
            <MenuItem key={idx}>
              {/* <CollapsibleTrigger asChild> */}
              <MenuLink as="button">
                <span>Settings</span>
              </MenuLink>
              {/* </CollapsibleTrigger> */}

              {/* <CollapsibleContent asChild> */}
              <MenuList>
                {entry.children.map((subEntry) => getMenuItem(subEntry, true))}
              </MenuList>
              {/* </CollapsibleContent> */}
            </MenuItem>
            // </Collapsible>
          );
        })}

        <MenuItem>
          <MenuLink href="https://docs-emplifai.mrhb.network" target="_blank">
            <DocsIcon width={24} height={24} />
            <span>Gitbook</span>
          </MenuLink>
        </MenuItem>
      </MenuList>
    </MenuWrapper>
  );
}

const MenuWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const MenuList = styled.ul`
  list-style: none;
  padding-inline: 24px;
  margin: 0;
`;

const MenuItem = styled.li``;

const MenuLink = styled.a`
  display: flex;
  align-items: center;
  gap: 14px;
  line-height: 1.4;
  border: none;
  background: none;
  height: 55px;
  width: 100%;
  cursor: pointer;
  transition: background-color 150ms ease;
  padding: 16px 27px;
  margin-bottom: 30px;

  &.active {
    background: ${(props) => props.theme.colors.primary};
    box-shadow: ${(props) => props.theme.shadows.btnActive};
    border-radius: 8px;
    color: #ffffff;
  }

  &.disabled-link {
    cursor: not-allowed;
    pointer-events: none;
  }

  svg {
    font-size: 17px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 16px;

    svg {
      font-size: 18px;
    }
  }
`;

export default SidebarMenu;
