import * as React from "react";
import type { SVGProps } from "react";
const SvgDashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 18"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M17 2v-.5h-5v3h5V2ZM7 2v-.5H2v7h5V2Zm10 8v-.5h-5v7h5V10ZM7 14v-.5H2v3h5V14Zm4-8.5v-5h7v5h-7Zm-3-5v9H1v-9h7Zm3 17v-9h7v9h-7Zm-10 0v-5h7v5H1Z"
    />
  </svg>
);
export default SvgDashboardIcon;
