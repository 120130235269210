import * as React from "react";
import type { SVGProps } from "react";
const SvgVaultIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 37 31"
    {...props}
  >
    <mask
      id="vault-icon_svg__a"
      width={32}
      height={26}
      x={5}
      y={5}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M32 26V5h5v26H5v-5h27Z" />
    </mask>
    <g mask="url(#vault-icon_svg__a)">
      <path
        fill="currentColor"
        d="M27.878 20.286A4.48 4.48 0 0 0 28.5 18a4.48 4.48 0 0 0-.622-2.286l1.792-1.794a1.125 1.125 0 0 0-1.59-1.59l-1.794 1.793A4.479 4.479 0 0 0 24 13.5a4.48 4.48 0 0 0-2.286.623L19.92 12.33a1.126 1.126 0 1 0-1.59 1.59l1.793 1.794A4.478 4.478 0 0 0 19.5 18c0 .834.227 1.616.622 2.286L18.33 22.08a1.126 1.126 0 1 0 1.59 1.59l1.794-1.793A4.48 4.48 0 0 0 24 22.5a4.48 4.48 0 0 0 2.286-.622l1.794 1.792a1.126 1.126 0 1 0 1.59-1.59l-1.793-1.794ZM24 20.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm-11.25-8.625a1.125 1.125 0 1 0-2.25 0v12.75a1.125 1.125 0 1 0 2.25 0v-12.75Zm-6.75 0A5.625 5.625 0 0 1 11.625 6h18.75A5.625 5.625 0 0 1 36 11.625v12.75A5.625 5.625 0 0 1 30.375 30h-18.75A5.625 5.625 0 0 1 6 24.375v-12.75Zm5.625-3.375a3.375 3.375 0 0 0-3.375 3.375v12.75a3.375 3.375 0 0 0 3.375 3.375h18.75a3.375 3.375 0 0 0 3.375-3.375v-12.75a3.375 3.375 0 0 0-3.375-3.375h-18.75Z"
      />
    </g>
    <path
      fill="currentColor"
      d="M21.878 14.286A4.48 4.48 0 0 0 22.5 12a4.48 4.48 0 0 0-.622-2.286L23.67 7.92a1.125 1.125 0 0 0-1.59-1.59l-1.794 1.793A4.479 4.479 0 0 0 18 7.5a4.48 4.48 0 0 0-2.286.623L13.92 6.33a1.125 1.125 0 1 0-1.59 1.59l1.793 1.794A4.478 4.478 0 0 0 13.5 12c0 .834.226 1.616.623 2.286L12.33 16.08a1.126 1.126 0 1 0 1.59 1.59l1.794-1.793A4.48 4.48 0 0 0 18 16.5a4.48 4.48 0 0 0 2.286-.623l1.794 1.793a1.126 1.126 0 1 0 1.59-1.59l-1.793-1.794ZM18 14.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5ZM6.75 5.625a1.125 1.125 0 0 0-2.25 0v12.75a1.125 1.125 0 0 0 2.25 0V5.625Zm-6.75 0A5.625 5.625 0 0 1 5.625 0h18.75A5.625 5.625 0 0 1 30 5.625v12.75A5.625 5.625 0 0 1 24.375 24H5.625A5.625 5.625 0 0 1 0 18.375V5.625ZM5.625 2.25A3.375 3.375 0 0 0 2.25 5.625v12.75a3.375 3.375 0 0 0 3.375 3.375h18.75a3.375 3.375 0 0 0 3.375-3.375V5.625a3.375 3.375 0 0 0-3.375-3.375H5.625Z"
    />
  </svg>
);
export default SvgVaultIcon;
