import { useQuery } from "@tanstack/react-query";

type TokensPriceResponse = {
  coins: {
    [key: string]: {
      decimals?: number;
      price: number;
      symbol: string;
      timestamp: number;
      confidence: number;
    };
  };
};

const tokens = [
  // asset tokens
  "coingecko:tether", // usdt
  "coingecko:usd-coin", // usdc
  "coingecko:binance-usd", // busd
  // reward tokens
  "coingecko:biswap", // bsw
  "coingecko:pancakeswap-token", // cake
  "coingecko:stargate-finance", // stg
  // native tokens
  "coingecko:binancecoin", // bnb
  "coingecko:ethereum", // eth
];

export async function getTokensPrice() {
  const resp = await fetch(
    `https://coins.llama.fi/prices/current/${tokens.join(",")}`
  );
  if (!resp.ok) {
    throw new Error("Failed to fetch tokens price");
  }
  const data = (await resp.json()) as TokensPriceResponse;

  const x = new Map();
  for (const item in data.coins) {
    const token = data.coins[item];
    x.set(token.symbol, token.price);
  }
  // symbol => price
  return Object.fromEntries(x) as Record<string, number>;
}

export function useTokensPrice() {
  return useQuery({
    queryKey: ["tokensPrice"],
    queryFn: getTokensPrice,
  });
}
