import * as React from "react";
import type { SVGProps } from "react";
const SvgTokenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9 20-9-5V5l9-5 9 5v10l-9 5ZM6.1 7.25a4.05 4.05 0 0 1 1.325-.925A3.92 3.92 0 0 1 9 6c.55 0 1.075.108 1.575.325.5.217.942.525 1.325.925l3-1.675L9 2.3 3.1 5.575l3 1.675Zm1.9 9.9v-3.275c-.9-.233-1.625-.708-2.175-1.425A3.917 3.917 0 0 1 5 10c0-.183.008-.354.025-.512.017-.159.05-.321.1-.488L2 7.25v6.575l6 3.325ZM9 12c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 9 8c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 7 10c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm1 5.15 6-3.325V7.25L12.875 9c.05.167.083.33.1.488.017.158.025.329.025.512 0 .917-.275 1.733-.825 2.45-.55.717-1.275 1.192-2.175 1.425v3.275Z"
    />
  </svg>
);
export default SvgTokenIcon;
