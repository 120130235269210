import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Providers from "./Providers";
import reportWebVitals from "./reportWebVitals";
import { Buffer } from "buffer";
import "fonts/HelveticaNow/style.css";
import "fonts/Poppins/style.css";

// polyfill Buffer for wagmi client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
