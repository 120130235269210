import { DefaultTheme } from "styled-components";
import base from "./base";
import { darkColors } from "./colors";

export const darkShadows = {
  level1: "0px 4px 42px 0px rgba(9, 32, 53, 0.25)",
  active: "0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)",
  btnActive: "0px 4px 24px 0px rgba(55, 51, 71, 1)",
  success: "0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)",
  warning: "0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)",
  focus: "0px 0px 0px 1px #7645D9, 0px 0px 0px 4px rgba(118, 69, 217, 0.6)",
  inset: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
};

const darkTheme: DefaultTheme = {
  ...base,
  shadows: darkShadows,
  colors: darkColors,
  isDark: true,
};

export default darkTheme;
