export const miroLockerABI = [
  {
    inputs: [
      { internalType: "contract IERC20", name: "_mrhbToken", type: "address" },
      { internalType: "uint256", name: "_startTime", type: "uint256" },
      { internalType: "uint256", name: "_maxLockWeeks", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "getTier",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
] as const;
