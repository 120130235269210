import * as React from "react";
import type { SVGProps } from "react";
const SvgTrophyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M13.5 2v.5H16c.824 0 1.5.676 1.5 1.5v1c0 2.29-1.724 4.164-3.952 4.444l-.287.036-.112.266a4.51 4.51 0 0 1-3.248 2.664l-.401.081V16.5h4v1h-9v-1h4v-4.009l-.4-.081A4.51 4.51 0 0 1 4.85 9.746l-.112-.266-.287-.036C2.224 9.164.5 7.29.5 5V4c0-.824.676-1.5 1.5-1.5h2.5v-2h9V2ZM2 3.5h-.5V5c0 1.523.984 2.803 2.33 3.29l.67.243V3.5H2Zm11.5 4.32v.713l.67-.243C15.516 7.803 16.5 6.523 16.5 5V3.5h-3v4.32Z"
    />
  </svg>
);
export default SvgTrophyIcon;
