import { DefaultTheme } from "styled-components";
import base from "./base";
import { lightColors } from "./colors";

export const lightShadows = {
  level1: "0px 8px 16px 0px rgba(113, 23, 23, 0.16)",
  active: "0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)",
  btnActive: "0px 4px 24px 0px rgba(228, 139, 120, 0.6)",
  success: "0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)",
  warning: "0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)",
  focus: "0px 0px 0px 1px #7645D9, 0px 0px 0px 4px rgba(118, 69, 217, 0.6)",
  inset: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
};

const lightTheme: DefaultTheme = {
  ...base,
  shadows: lightShadows,
  isDark: false,
  colors: lightColors,
};

export default lightTheme;
