import { IconButton } from "components/Button";
import { Text } from "components/Text";
import { BellIcon, TokenIcon, VaultIcon } from "components/Icons";
import styled from "styled-components";

// import ConnectBtn from "./Connect";
import { useTheme } from "hooks/use-theme";
import { useMiroTier } from "hooks/use-miro-tier";
import { SwitchThumb, ThemeSwitch } from "components/Switch";
import { MoonIcon, SunIcon } from "components/Icons";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { kFormatter, valueToCurrency } from "utils/numbers";
import { InfoCard } from "features/dashboard/components";
import { useTokensPrice } from "hooks/use-tokens";
import { useAllVaults } from "features/vaults/api/getAllVaults";
import { useEffect, useRef, useState } from "react";
import SidebarMenu from "components/Sidebar/SidebarMenu";
import { useAccount } from "wagmi";

const light_logo = "/images/emplifai_logo_black.png";
const dark_logo = "/images/emplifai_logo_white.png";

const Header = () => {
  const { isDark, toggleTheme } = useTheme();
  const { data: miroTier } = useMiroTier();
  const tokensPriceQuery = useTokensPrice();
  const { data: vaults, isLoading } = useAllVaults();
  const { isConnected } = useAccount();

  const ref = useRef<HTMLImageElement | null>(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (window.outerWidth > 768) {
      setIsSidebarOpen(false);
      setIsSmallScreen(false);
    } else setIsSmallScreen(true);
  }, [isSmallScreen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  window.addEventListener("resize", () => {
    if (window.outerWidth > 768) {
      setIsSidebarOpen(false);
      setIsSmallScreen(false);
    } else setIsSmallScreen(true);
  });

  return (
    <HeaderWrapper>
      {isSmallScreen && (
        <>
          <img
            src={isDark ? dark_logo : light_logo}
            alt=""
            className="logo"
            onClick={toggleSidebar}
            ref={ref}
          />

          <div className={`sidebar ${isSidebarOpen ? "open" : "close"}`}>
            <div className="sidebar-content">
              <SidebarMenu />

              <div className="tier-info">
                <TokenIcon width={18} height={20} />
                <Text as="span" variant="h7">
                  Tier {miroTier}
                </Text>
              </div>
            </div>
          </div>
        </>
      )}

      {!isSmallScreen && (
        <>
          <div className="tier-info">
            <TokenIcon width={18} height={20} />
            <Text as="span" variant="h7">
              Tier {miroTier}
            </Text>
          </div>
          <div style={{ marginLeft: "auto" }} />
        </>
      )}

      <div className="tvl">
        {/* <Text variant="h7" as="span">
          Emplifai TVL: ${kFormatter(22222222)}
        </Text> */}
      </div>

      <ThemeSwitch defaultChecked={isDark} onCheckedChange={toggleTheme}>
        <MoonIcon className="moon-icon" />
        <SunIcon className="sun-icon" />
        <SwitchThumb />
      </ThemeSwitch>

      {/* <IconButton className="bell-icon" variant="ghost">
        <BellIcon width="16px" height="20px" />
      </IconButton> */}

      {/* <LanguageDropdown /> */}

      <div className="connect">
        <ConnectButton />
      </div>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 4.5rem;
  backdrop-filter: blur(6px);

  ${(props) => props.theme.mediaQueries.md} {
    left: var(--sidebar-width);
  }

  .tier-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 18px 24px;
    text-transform: capitalize;
  }

  .bell-icon {
    padding: 0.7rem;
    color: ${({ theme }) =>
      theme.isDark ? theme.colors.primary : theme.colors.text};
  }

  .tvl {
    color: ${({ theme }) =>
      theme.isDark ? theme.colors.text : theme.colors.black};
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 14px;
    text-align: center;
    border-radius: 37px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
  }

  .sidebar {
    position: fixed;
    top: 80px;
    left: -250px; /* Initially hidden */
    width: 250px;
    height: 100vh;
    background-color: #333;
    color: white;
    transition: left 0.3s ease-in-out;
  }

  .connect {
    margin-left: 40px;
  }

  .open {
    left: 0; /* Slide in when open */
  }

  .toggle-button {
    padding: 10px;
    background-color: #444;
    color: white;
    border: none;
    cursor: pointer;
  }

  .sidebar-content {
    padding: 20px;
  }

  .logo {
    width: 70px;
  }

  @media (max-width: 750px) {
    max-width: 650px;
  }
`;
export default Header;
