import { Suspense, lazy } from "react";
import { Box } from "components/Box";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import {
  createBrowserRouter,
  // Link,
  Outlet,
  useRouteError,
} from "react-router-dom";
import Loader from "components/Loader";

const Portfolio = lazy(() => import("features/dashboard/pages/Portfolio"));
const Vaults = lazy(() => import("features/vaults/pages/Vaults"));
const VaultDetail = lazy(() => import("features/vaults/pages/Detail"));
const Bridge = lazy(() => import("features/bridge/pages/Bridge"));
const Tutorials = lazy(() => import("features/tutorial/pages/Tutorials"));
const ComingSoon = lazy(() => import("./ComingSoon"));
const Page404 = lazy(() => import("./Page404"));

function SidebarLayout() {
  return (
    <>
      <Header />
      <Sidebar />
      <Box
        // mt="var(--header-height)"

        ml={[0, 0, 0, "var(--sidebar-width)"]}
        p="calc(var(--header-height) + 28px) 0px 50px 28px"
      >
        <Suspense
          fallback={
            <div className="g__page-loader">
              <Loader size={100} borderWidth={8} />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
}

function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  if (error.status === 404) {
    return <Page404 />;
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

// tutorial: https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
  {
    path: "/",
    element: <SidebarLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Portfolio />,
      },
      {
        path: "vaults",
        element: <Vaults />,
      },
      {
        path: "vaults/:id",
        element: <VaultDetail />,
      },
      {
        path: "/bridge",
        element: <Bridge />,
      },
      {
        path: "/tools",
        element: <ComingSoon />,
      },
      {
        path: "/docs",
        element: <ComingSoon />,
      },
      {
        path: "/tutorials",
        element: <Tutorials />,
      },
    ],
  },
]);

export default router;
