import { useState } from "react";
import styled, { useTheme } from "styled-components";
import SidebarMenu from "./SidebarMenu";

export const light_logo = "/images/emplifai_logo_black.png";
export const dark_logo = "/images/emplifai_logo_white.png";
function Sidebar() {
  const { isDark } = useTheme();

  return (
    // TODO: replace this static bar with bootsrap offcanvas

    <SidebarWrapper>
      <img src={isDark ? dark_logo : light_logo} alt="" className="site-name" />
      <SidebarMenu />
    </SidebarWrapper>
  );
}

const SidebarWrapper = styled.div`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  border-right: 1px solid #b954544d;

  .site-name {
    padding: 1.5rem 1rem 30px;
    letter-spacing: -0.02em;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    flex-direction: column;
    min-width: var(--sidebar-width);
    width: var(--sidebar-width);
    height: 100%;
    z-index: 7;
  }
`;

export default Sidebar;
