import { Colors } from "./types";

export const baseColors = {
  white: "#FFFFFF",
  black: "#000000",
};

export const lightColors: Colors = {
  ...baseColors,
  primary: "#ff8c8c",
  info: "#ffaa2a",
  success: "#2aff66",
  warning: "#ffaa2a",
  error: "#ff8c8c",

  background: "#ffeacd",
  backgroundGradient:
    "linear-gradient(180deg, rgba(255, 140, 140, 0.5) 0%, rgba(255, 140, 140, 0) 83.75%);",

  text: "#000",
  textSubtle: "#ff8c8c",
  placeholderText: "#808080",

  borderColor: "#3A354F",
  borderColorMidLight: "#D2D2D2",
  borderColorLight: "#EAEAEA",

  buttonGradient: "linear-gradient(270deg, #5BB3D9 0%, #444259 100%)",
  buttonDisable: "#ff8c8c",
  cardGradient:
    "radial-gradient(100% 100% at 100% 0%, #FFDFDF 0%, #FFEADC 63.31%)",
  divider: "#85839A",
  overlay: "rgba(0, 0, 0, 0.5)",
  overlayGradient:
    "linear-gradient(116.6deg, rgba(199, 164, 164, 0.84) 0%, rgba(16, 104, 154, 0.81) 100%)",
  tooltipBg: "#ffffff",
  tooltipText: "#000",
};

export const darkColors: Colors = {
  ...baseColors,
  primary: "#ff8c8c",
  info: "#FFAA2A",
  success: "#2AFF66",

  warning: "#FFAA2A",
  error: "#ff8c8c",
  // 009393
  // 617186
  background: "#FFEACD",
  backgroundGradient:
    "linear-gradient(180deg, #444259 0%, rgba(31, 29, 60, 0.86) 100%)",

  text: "#fff",
  textSubtle: "#ff8c8c",
  placeholderText: "#808080",

  borderColor: "#85839A",
  borderColorMidLight: "#D2D2D2",
  borderColorLight: "#EAEAEA",

  buttonGradient: "linear-gradient(270deg, #5BB3D9 0%, #444259 100%)",
  buttonDisable: "#ff8c8c",
  cardGradient: `linear-gradient(268deg, #5F658E 0%, #3E3B53 100%), 
    linear-gradient(268deg, #615D87 0%, #6E6A93 100%)`,
  divider: "#D2D2D2",
  overlay: "rgba(0, 0, 0, 0.5)",
  overlayGradient:
    "linear-gradient(116.6deg, rgba(47, 46, 57, 0.94) 0%, rgba(19, 8, 40, 0.89) 100%)",

  tooltipBg: "#fff",
  tooltipText: "#000",
};
