import styled, { keyframes } from "styled-components";
import { space, SpaceProps } from "styled-system";

type LoaderProps = React.HTMLAttributes<unknown> & {
  as?: keyof JSX.IntrinsicElements;
  type?: "primary" | "error" | "success";
  children?: React.ReactNode;
  size?: number;
  borderWidth?: number;
  style?: React.CSSProperties;
} & SpaceProps;

const Loader = ({
  children,
  size,
  borderWidth,
  type = "primary",
  ...props
}: LoaderProps) => {
  const ariaLabel = children ? "" : "Loader";
  return (
    <StyledLoaderContainer className="loader-container" {...props}>
      <>
        <StyledLoader
          className={`loader load-${type}`}
          size={size}
          borderWidth={borderWidth}
          aria-label={ariaLabel}
        >
          <i className="_1" />
          <i className="_2" />
        </StyledLoader>
        {children && (
          <StyledLoaderLabel className="loader-label">
            {children}
          </StyledLoaderLabel>
        )}
      </>
    </StyledLoaderContainer>
  );
};

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledLoaderContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  --loading-text-color: ${({ theme }) => theme.colors.text};

  .load-primary {
    --loading-color: ${({ theme }) => theme.colors.primary};
  }

  .load-error {
    --loading-color: ${({ theme }) => theme.colors.error};
  }

  .load-success {
    --loading-color: ${({ theme }) => theme.colors.success};
  }

  ${space}
`;

const StyledLoader = styled.span<{ size?: number; borderWidth?: number }>`
  --loading-size: ${({ size }) => size || 44}px;
  --loading-border: ${({ borderWidth }) => borderWidth || 4}px;
  position: relative;
  inset: 0px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  user-select: none;
  display: flex;
  border-radius: 50%;
  width: var(--loading-size);
  height: var(--loading-size);

  i {
    position: absolute;
    border-radius: inherit;
    top: -1px;
    width: 90%;
    height: 90%;
  }

  ._1 {
    border: var(--loading-border) solid var(--loading-color);
    border-top: var(--loading-border) solid transparent;
    border-left: var(--loading-border) solid transparent;
    border-right: var(--loading-border) solid transparent;
    animation: ${rotate} 0.8s ease infinite;
  }

  ._2 {
    border: var(--loading-border) dotted var(--loading-color);
    border-top: var(--loading-border) solid transparent;
    border-left: var(--loading-border) solid transparent;
    border-right: var(--loading-border) solid transparent;
    animation: ${rotate} 0.8s linear infinite;
    opacity: 0.5;
  }
`;

const StyledLoaderLabel = styled.label`
  color: ${(props) => props.theme.colors.text};
  fontsize: 0.875rem;
  * {
    margin: 0;
  }
`;

export const PageLoader = () => (
  <StyledPageLoader>
    <Loader size={50} />
  </StyledPageLoader>
);

export const StyledPageLoader = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loader;
