import { cloneElement, isValidElement } from "react";
import StyledButton from "./StyledButton";
import MoonLoader from "react-spinners/MoonLoader";
import { ButtonProps, scales, variants } from "./types";
import { useTheme } from "hooks/use-theme";
import { forwardRef } from "react";

const Button = forwardRef<React.ElementRef<"button">, ButtonProps<"button">>(
  (props, forwardedRef) => {
    const {
      startIcon,
      endIcon,
      external,
      className,
      isLoading,
      disabled,
      children,
      ...rest
    } = props;

    const externalLinkProps = {
      target: "_blank",
      rel: "noopener noreferrer",
    };
    const internalProps = external ? externalLinkProps : {};
    const isDisabled = isLoading || disabled;
    const classNames = className ? [className] : [];
    const { theme } = useTheme();

    if (isLoading) {
      classNames.push("button--loading");
    }

    if (isDisabled && !isLoading) {
      classNames.push("button--disabled");
    }

    return (
      <StyledButton
        isLoading={isLoading}
        className={classNames.join(" ")}
        disabled={isDisabled}
        ref={forwardedRef as any}
        {...internalProps}
        {...rest}
      >
        <>
          {isValidElement(startIcon) &&
            cloneElement(startIcon, {
              // @ts-ignore
              mr: "1rem",
            })}
          {children}
          {isLoading && (
            <div className="loader">
              <MoonLoader
                color={theme.colors.error}
                size={20}
                speedMultiplier={0.5}
                cssOverride={{ display: "flex", margin: "0 auto" }}
              />
            </div>
          )}
          {isValidElement(endIcon) &&
            cloneElement(endIcon, {
              // @ts-ignore
              ml: "1rem",
            })}
        </>
      </StyledButton>
    );
  }
);

Button.defaultProps = {
  isLoading: false,
  external: false,
  variant: variants.PRIMARY,
  scale: scales.MD,
  disabled: false,
};

export default Button;
