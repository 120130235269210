import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "config/constants";
import { client } from "lib/request";
import { graphql } from "gql";
import { GetAllVaultsQueryVariables } from "gql/graphql";

export const allVaultsDocument = graphql(/* GraphQL */ `
  query getAllVaults(
    $first: Int
    $skip: Int
    $orderBy: Vault_orderBy
    $orderDirection: OrderDirection
    $where: Vault_filter
  ) {
    vaults(
      skip: $skip
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
    ) {
      id
      type
      totalValueLocked
      want {
        decimals
      }
    }
  }
`);

type UseAllVaultsOptions = {
  variables?: GetAllVaultsQueryVariables;
};

export function useAllVaults({ variables }: UseAllVaultsOptions = {}) {
  return useQuery({
    queryKey: [queryKeys.ALL_VAULTS, variables],
    queryFn: async () => {
      try {
        const { vaults } = await client.request(
          allVaultsDocument,
          variables ?? {}
        );
        return vaults;
      } catch (e) {
        console.log(e);
        return
      };
    },
  });
}
