import { MediaQueries, Breakpoints, Spacing } from "./types";

export const breakpointMap: Record<string, number> = {
  xs: 360,
  sm: 480,
  md: 768,
  lg: 1020,
  xl: 1400,
  xxl: 1920,
};

const breakpoints: Breakpoints = Object.values(breakpointMap).map(
  (breakpoint) => `${breakpoint}px`
);

const mediaQueries: MediaQueries = {
  xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
  xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
};


const spacing: Spacing = [0, 4, 8, 16, 24, 32, 48, 64];

const radii = {
  small: "4px",
  default: "16px",
  card: "32px",
  circle: "50%",
};

const zIndices = {
  dropdown: 10,
  modal: 100,
};

const theme = {
  siteWidth: 1200,
  fonts: {
    machina: "'Neue Machina', sans-serif",
    helvetica: "'Helvetica Now Display', sans-serif",
    rubik: "Rubik, sans-serif",
    poppins: "Poppins, sans-serif",
  },
  breakpoints,
  mediaQueries,
  spacing,
  radii,
  zIndices,
};

export default theme;
