import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { WagmiConfig } from "wagmi";
import { HelmetProvider } from "react-helmet-async";
import { GlobalStyle } from "theme/Global";
import { queryClient } from "lib/react-query";
import { chains, wagmiClient } from "lib/wagmi";
import { AuthProvider } from "contexts/AuthContext";
import { ThemeProvider } from "contexts/ThemeContext";
import { darkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <WagmiConfig config={wagmiClient}>
        <RainbowKitProvider
          appInfo={{
            appName: "EmplifAI",
            learnMoreUrl: "https://mrhb.network",
          }}
          chains={chains}
          modalSize="compact"
          theme={darkTheme()}
        >
          <ThemeProvider>
            <GlobalStyle />
            <AuthProvider>
              <HelmetProvider>{children}</HelmetProvider>
            </AuthProvider>
          </ThemeProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
}
