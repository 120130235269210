import styled from "styled-components";
import * as React from "react";
import * as SwitchPrimitive from "@radix-ui/react-switch";
// import { useController, UseControllerProps } from "react-hook-form";

export const SwitchLabel = styled.label`
  display: inline-block;
  position: relative;
  line-height: 0;
  --switch-track-width: 50px;
  --switch-track-height: 22px;
  --switch-track-diff: calc(
    var(--switch-track-width) - var(--switch-track-height)
  );
  --switch-thumb-x: var(--switch-track-diff);
`;

const StyledSwitch = styled(SwitchPrimitive.Root)`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: 9999px;
  padding: 3px;
  border: none;
  width: var(--switch-track-width);
  height: var(--switch-track-height);
  --switch-bg: linear-gradient(180deg, #b8b8b8 0%, #dcdcdc 100%);
  background: var(--switch-bg);
  transition: background 200ms;

  &[data-state="checked"] {
    --switch-bg: ${(props) => props.theme.colors.primary};
  }
`;

export const ThemeSwitch = styled(StyledSwitch)`
  --switch-track-width: 50px;
  --switch-track-height: 22px;
  --switch-thumb-x: calc(
    var(--switch-track-width) - var(--switch-track-height)
  );
  --switch-bg: #ffe7dd;

  position: relative;
  .moon-icon {
    position: absolute;
    top: 4px;
    left: 6px;
    font-size: 20px;
    display: none;
  }

  .sun-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
  }

  &[data-state="checked"] {
    --switch-bg: #2b293d;
    .sun-icon {
      display: none;
    }

    .moon-icon {
      display: block;
    }
  }
`;

const StyledThumb = styled(SwitchPrimitive.Thumb)`
  background: linear-gradient(270deg, #5bb3d9 0%, #444259 100%),
    linear-gradient(0deg, #4b486b, #4b486b);
  /* border: 1px solid #4b486b; */
  border-radius: inherit;
  width: var(--switch-track-height);
  height: var(--switch-track-height);
  will-change: transform;
  transition: transform 200ms;

  &[data-state="checked"] {
    transform: translateX(var(--switch-thumb-x));
  }
`;

// should forward ref

export const SwitchThumb = StyledThumb;

export const Switch = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof StyledSwitch>
>((props, ref) => {
  return (
    <SwitchLabel>
      <StyledSwitch {...props} ref={ref}>
        <StyledThumb />
      </StyledSwitch>
    </SwitchLabel>
  );
});

/**  name should be used in htmlFor of label as that is used in id */
// export const ControlledSwitch = React.forwardRef((
//   props: UseControllerProps<any> & React.ComponentProps<typeof Switch>
// ) => {
//   const { name, rules, defaultValue = false, control, ...rest } = props;
//   const { field } = useController({
//     control,
//     name,
//     defaultValue,
//     rules,
//   });
//   return (
//     <Switch
//       {...field}
//       {...rest}
//       // no clue what this does, but it was used in an example
//       // value={undefined}
//       id={field.name}
//       checked={field.value}
//       onCheckedChange={field.onChange}
//     >
//       <SwitchThumb />
//     </Switch>
//   );
// });
