// Check environment
const isTest = process.env.NODE_ENV === 'development';

export const REACT_APP_ALCHEMY_API_KEY = 'A_6DoNRrREZXw4cj6HBrGuHM31Ulgy-V'
export const REACT_APP_PROJECT_ID = '6c5189b1a6245010063c32620fccced5'
export const REACT_APP_RPC_URL = 'https://nd-805-652-578.p2pify.com/442699f26d500e35fc3d847e8e5a0a66'
export const SUBGRAPH_URL_API = isTest ? 'https://api.studio.thegraph.com/query/49464/lh-subgraph/version/latest'
    : 'https://gateway-arbitrum.network.thegraph.com/api/538daa95121d03057c8365a7b087bbdb/subgraphs/id/BpxeJegjjbkAZFkCs997tMZAmqLvCQaKBibpLqQ5WpEz'
export const REACT_APP_BISWAP_GRAPH_URL = 'https://api.thegraph.com/subgraphs/name/biswapcom/exchange5'
export const REACT_APP_SOCKET_API_KEY = '72a5b4b0-e727-48be-8aa1-5da9d62fe635'
export const REACT_APP_PANCAKE_GRAPH_URL = ''