import * as React from "react";
import type { SVGProps } from "react";
const SvgSunIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <g clipPath="url(#sun-icon_svg__a)">
      <path
        fill="#FFAC30"
        d="M13.781 9A4.782 4.782 0 1 0 9 13.781 4.788 4.788 0 0 0 13.781 9ZM9 14.906a.563.563 0 0 1 .563.563v1.406a.562.562 0 1 1-1.126 0v-1.406A.563.563 0 0 1 9 14.906Zm-4.971-1.73a.57.57 0 0 1 .794 0 .562.562 0 0 1 0 .795l-.991.998a.568.568 0 0 1-1.01-.389.57.57 0 0 1 .208-.412l.999-.991ZM3.094 9a.563.563 0 0 1-.563.563H1.125a.562.562 0 1 1 0-1.126h1.406A.563.563 0 0 1 3.094 9Zm.935-4.177-.999-.991a.57.57 0 0 1 0-.802.598.598 0 0 1 .401-.161c.15 0 .293.058.401.161l.991.999a.563.563 0 0 1-.794.794ZM9 3.093a.563.563 0 0 1-.563-.562V1.125a.562.562 0 1 1 1.126 0v1.406A.563.563 0 0 1 9 3.094Zm4.971 1.73a.563.563 0 0 1-.794-.794l.991-.999a.583.583 0 0 1 .4-.161c.15.002.293.06.402.161a.57.57 0 0 1 0 .802l-.999.991Zm2.904 4.74h-1.406a.563.563 0 1 1 0-1.126h1.406a.562.562 0 1 1 0 1.126Zm-3.298 3.452a.55.55 0 0 1 .394.162l.998.991a.57.57 0 0 1-.801.802l-.991-.999a.563.563 0 0 1 .183-.916.548.548 0 0 1 .217-.04Z"
      />
    </g>
    <defs>
      <clipPath id="sun-icon_svg__a">
        <path fill="currentColor" d="M0 18h18V0H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSunIcon;
