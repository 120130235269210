import { Chain, getWalletConnectConnector } from "@rainbow-me/rainbowkit";
import { isAndroid } from "./isMobile";

type InstructionStepName = "install" | "create" | "scan" | "refresh";

export interface MyWalletOptions {
  projectId: string;
  chains: Chain[];
}

function sahalWallet(params: MyWalletOptions) {
  const { chains, projectId } = params;
  return {
    id: "sahal-wallet",
    name: "Sahal Wallet",
    iconUrl:
      "https://play-lh.googleusercontent.com/_rf7hYFayWLWgrYl196Z1LhQyRH5mFRWs_1TpkBScw8lP5cUdFEJ1tUm9pCGTeHX1g",
    iconBackground: "#0c2f78",
    downloadUrls: {
      android:
        "https://play.google.com/store/apps/details?id=sahal.wallet.app&hl=en&gl=US&pli=1",
      ios: "https://apps.apple.com/ae/app/sahal-wallet/id1602366920",
      qrCode: "https://3vpj.short.gy/sahal-wallet",
      chrome: "https://chrome.google.com/webstore/detail/my-wallet",
    },
    createConnector: () => {
      const connector = getWalletConnectConnector({ projectId, chains });
      return {
        connector,
        mobile: {
          getUri: async () => {
            const provider = await connector.getProvider();
            const uri = await new Promise<string>((resolve) =>
              provider.once("display_uri", resolve)
            );
            return isAndroid()
              ? uri
              : `sahalwallet://wc?uri=${encodeURIComponent(uri)}`;
          },
        },
        qrCode: {
          getUri: async () => {
            const provider = await connector.getProvider();
            const uri = await new Promise<string>((resolve) =>
              provider.once("display_uri", resolve)
            );
            return uri;
          },
          instructions: {
            learnMoreUrl: "https://mrhb.network/get-started",
            steps: [
              {
                description:
                  "Install Sahal Wallet from the App Store or Google Play Store.",
                step: "install" as InstructionStepName,
                title: "Install Sahal Wallet",
              },
              {
                title: "Create or Import a Wallet",
                description:
                  "Be sure to back up your wallet using a secure method. Never share your secret phrase with anyone.",
                step: "create" as InstructionStepName,
              },
              {
                title: "Scan the QR Code",
                description:
                  "from setttings > wallet connect click the plus icon and scan the QR code ",
                step: "scan" as InstructionStepName,
              },
            ],
          },
        },
        extension: {
          instructions: {
            learnMoreUrl: "https://mrhb.network/get-started",
            steps: [
              {
                description:
                  "We recommend pinning My Wallet to your taskbar for quicker access to your wallet.",
                step: "install" as InstructionStepName,
                title: "Install the My Wallet extension",
              },
              {
                description:
                  "Be sure to back up your wallet using a secure method. Never share your secret phrase with anyone.",
                step: "create" as InstructionStepName,
                title: "Create or Import a Wallet",
              },
              {
                description:
                  "Once you set up your wallet, click below to refresh the browser and load up the extension.",
                step: "refresh" as InstructionStepName,
                title: "Refresh your browser",
              },
            ],
          },
        },
      };
    },
  };
}

export default sahalWallet;
