import { REACT_APP_BISWAP_GRAPH_URL, REACT_APP_PANCAKE_GRAPH_URL, REACT_APP_SOCKET_API_KEY } from "config";
import { SupportedChains, ChainInfo } from "features/vaults/types";

export const queryKeys = {
  ALL_VAULTS: "all-vaults",
  USER_VAULTS: "user-vaults",
  VAULT: "vault",
  BISWAP_PAIR: "biswap-pair",
  PANCAKE_PAIR: "pancake-pair",
};

// biswap
export const BISWAP_GRAPH_URL = REACT_APP_BISWAP_GRAPH_URL;
export const BISWAP_CHEF = "0xdbc1a13490deef9c3c12b44fe77b503c1b061739";
export const BISWAP_ROUTER = "0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8";
export const BISWAP_FACTORY = "0x858E3312ed3A876947EA49d572A7C42DE08af7EE";

// pancake
export const PANCAKE_GRAPH_URL = REACT_APP_PANCAKE_GRAPH_URL;
export const PANCAKE_CHEF = "0xa5f8c5dbd5f286960b9d90548680ae5ebff07652";
export const PANCAKE_ROUTER = "0x10ed43c718714eb63d5aa57b78b54704e256024e";
export const PANCAKE_FACTORY = "0xca143ce32fe78f1f7019d7d551a6402fc5350c73";
// TODO: remove this
export const PANCAKE_STABLE_SWAP = "0x3efebc418efb585248a0d2140cfb87afcc2c63dd";
export const PANCAKE_STABLESWAP = "0xa680d27f63fa5e213c502d1b3ca1eb6a3c1b31d6";

// stargate
export const STARGATE_CHEF = "0x3052a0f6ab15b4ae1df39962d5ddefaca86dab47";
export const STARGATE_API = "https://stargate.finance/.netlify/functions";
// lookup pool name from address on stargate
export const STARGATE_ADDRESSES = {
  "0x9aa83081aa06af7208dcc7a4cb72c94d057d2cda": "USDT",
  "0x98a5737749490856b401db5dc27f522fc314a4e1": "BUSD",
} as Record<string, string>;

// ellipsis
export const ELLIPSIS_ROUTER = "0xC0cD22471F7E923b10672A98793F68f041f607EB";

// contract addresses
export const CONTRACT_ADDRESSES = {
  56: {
    miroLocker: "0x31401ff5563C5EA19B9584583194617F613222CD",
  },
  97: {
    miroLocker: "0x27d616888807a6749d2ae4589368f3e68e1d3e0c",
  },
} as const;

// exchange-<tokensymbol> => llama pool id
// https://yields.llama.fi/poolsEnriched?pool=<llama-pool-id>
/** key format is \<exchange>-\<token0symbol>-\<token1 or chain symbol>  */
export const DEFI_LLAMA_POOL_IDS: Record<string, string> = {
  "pancake-USDT-USDC": "01154cd5-0642-40cd-b1cf-1de07ffaff4c",
  // b2e9d5fe-19ca-456b-8cd5-c296d635136f
  "biswap-USDT-BUSD": "2495edd7-2dbe-439e-9572-0acaf1736ad2",
  "stargate-BUSD-BSC": "72a0cd12-822f-4b71-a063-9887fbfff463",
};

/** supported chains data */
export const SUPPORTED_CHAINS: Record<SupportedChains, ChainInfo> = {
  bsc: {
    id: 56,
    name: "Binance Smart Chain",
    icon: "/icons/bsc-icon.svg",
    symbol: "BSC",
    network: `bsc`,
    blocksPerDay: 28700,
  },
  arbitrum: {
    id: 42161,
    name: "Arbitrum",
    icon: "/icons/arb_icon.svg",
    symbol: "ARB",
    network: `arbitrum`,
    blocksPerDay: 28700,
  },
  polygon: {
    id: 137,
    name: "Polygon",
    icon: "/icons/MATIC_icon.svg",
    symbol: "MATIC",
    network: `polygon`,
    blocksPerDay: 28700,
  },
};

/** supported chains by id */
export const SUPPORTED_CHAINS_ID: Record<number, ChainInfo> = {
  [`56`]: SUPPORTED_CHAINS[`bsc`],
  [`42161`]: SUPPORTED_CHAINS[`arbitrum`],
  [`137`]: SUPPORTED_CHAINS[`polygon`],
};

export const SOCKET_API = REACT_APP_SOCKET_API_KEY;
export const SocketFeeTakerAddress: Record<number, string> = {
  324: "0x1b06089da471355f8f05c7a6d8de1d9dac397629",

  1101: "0x1b06089da471355f8f05c7a6d8de1d9dac397629",

  1: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  56: "0xd33d00a96fcb03fb21a0d6396d85734f02d11a39",

  10: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  100: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  137: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  43114: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  42161: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  1313161554: "0xa913eedf24bff996949a7dfcce356c7768a673d7",

  250: "0xa913eedf24bff996949a7dfcce356c7768a673d7",
};
