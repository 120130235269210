/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
  "\n  query getAllVaults(\n    $first: Int\n    $skip: Int\n    $orderBy: Vault_orderBy\n    $orderDirection: OrderDirection\n    $where: Vault_filter\n  ) {\n    vaults(\n      skip: $skip\n      first: $first\n      orderBy: $orderBy\n      orderDirection: $orderDirection\n      where: $where\n    ) {\n      id\n      type\n      totalValueLocked\n      want {\n        decimals\n      }\n    }\n  }\n":
    types.GetAllVaultsDocument,
  "\n  query getUserVaults(\n    $id: ID!\n    $first: Int\n    $skip: Int\n    $orderBy: Deposit_orderBy\n    $orderDirection: OrderDirection\n    $where: Deposit_filter\n  ) {\n    user(id: $id) {\n      vaultDeposits(\n        skip: $skip\n        first: $first\n        orderBy: $orderBy\n        orderDirection: $orderDirection\n        where: $where\n      ) {\n        id\n        vault\n        shares\n      }\n    }\n  }\n":
    types.GetUserVaultsDocument,
  "\n  fragment tokenData on Token {\n    id\n    decimals\n    name\n    symbol\n  }\n":
    types.TokenDataFragmentDoc,
  "\n  fragment zapperData on Zapper {\n    id\n    smartWallet\n    defaultDepositFee\n    silverDepositFee\n    goldDepositFee\n  }\n":
    types.ZapperDataFragmentDoc,
  "\n  query getVault($id: ID!) {\n    vault(id: $id) {\n      __typename\n      id\n      want {\n        ...tokenData\n      }\n      paused\n      pps\n      totalValueLocked\n      lastTimeHarvested\n      totalProfits\n      ... on VaultDualsided {\n        strategy {\n          lpToken0 {\n            ...tokenData\n          }\n          lpToken1 {\n            ...tokenData\n          }\n          govFee\n          totalFee\n          poolId\n          router\n        }\n        zapper {\n          ...zapperData\n        }\n      }\n      ... on VaultStargate {\n        strategy {\n          underlyingToken {\n            ...tokenData\n          }\n          govFee\n          totalFee\n          chefId\n          poolId\n          router\n        }\n        zapper {\n          ...zapperData\n        }\n      }\n    }\n  }\n":
    types.GetVaultDocument,
  "\n  query getZapper($id: ID!) {\n    zapper(id: $id) {\n      id\n      defaultDepositFee\n      silverDepositFee\n      goldDepositFee\n      smartWallet\n    }\n  }\n":
    types.GetZapperDocument,
  "\n    query ppsVault($id: ID!, $block: Block_height) {\n      vault(id: $id, block: $block) {\n        pps\n      }\n    }\n  ":
    types.PpsVaultDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 **/
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getAllVaults(\n    $first: Int\n    $skip: Int\n    $orderBy: Vault_orderBy\n    $orderDirection: OrderDirection\n    $where: Vault_filter\n  ) {\n    vaults(\n      skip: $skip\n      first: $first\n      orderBy: $orderBy\n      orderDirection: $orderDirection\n      where: $where\n    ) {\n      id\n      type\n      totalValueLocked\n      want {\n        decimals\n      }\n    }\n  }\n"
): typeof documents["\n  query getAllVaults(\n    $first: Int\n    $skip: Int\n    $orderBy: Vault_orderBy\n    $orderDirection: OrderDirection\n    $where: Vault_filter\n  ) {\n    vaults(\n      skip: $skip\n      first: $first\n      orderBy: $orderBy\n      orderDirection: $orderDirection\n      where: $where\n    ) {\n      id\n      type\n      totalValueLocked\n      want {\n        decimals\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getUserVaults(\n    $id: ID!\n    $first: Int\n    $skip: Int\n    $orderBy: Deposit_orderBy\n    $orderDirection: OrderDirection\n    $where: Deposit_filter\n  ) {\n    user(id: $id) {\n      vaultDeposits(\n        skip: $skip\n        first: $first\n        orderBy: $orderBy\n        orderDirection: $orderDirection\n        where: $where\n      ) {\n        id\n        vault\n        shares\n      }\n    }\n  }\n"
): typeof documents["\n  query getUserVaults(\n    $id: ID!\n    $first: Int\n    $skip: Int\n    $orderBy: Deposit_orderBy\n    $orderDirection: OrderDirection\n    $where: Deposit_filter\n  ) {\n    user(id: $id) {\n      vaultDeposits(\n        skip: $skip\n        first: $first\n        orderBy: $orderBy\n        orderDirection: $orderDirection\n        where: $where\n      ) {\n        id\n        vault\n        shares\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment tokenData on Token {\n    id\n    decimals\n    name\n    symbol\n  }\n"
): typeof documents["\n  fragment tokenData on Token {\n    id\n    decimals\n    name\n    symbol\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment zapperData on Zapper {\n    id\n    smartWallet\n    defaultDepositFee\n    silverDepositFee\n    goldDepositFee\n  }\n"
): typeof documents["\n  fragment zapperData on Zapper {\n    id\n    smartWallet\n    defaultDepositFee\n    silverDepositFee\n    goldDepositFee\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getVault($id: ID!) {\n    vault(id: $id) {\n      __typename\n      id\n      want {\n        ...tokenData\n      }\n      paused\n      pps\n      totalValueLocked\n      lastTimeHarvested\n      totalProfits\n      ... on VaultDualsided {\n        strategy {\n          lpToken0 {\n            ...tokenData\n          }\n          lpToken1 {\n            ...tokenData\n          }\n          govFee\n          totalFee\n          poolId\n          router\n        }\n        zapper {\n          ...zapperData\n        }\n      }\n      ... on VaultStargate {\n        strategy {\n          underlyingToken {\n            ...tokenData\n          }\n          govFee\n          totalFee\n          chefId\n          poolId\n          router\n        }\n        zapper {\n          ...zapperData\n        }\n      }\n    }\n  }\n"
): typeof documents["\n  query getVault($id: ID!) {\n    vault(id: $id) {\n      __typename\n      id\n      want {\n        ...tokenData\n      }\n      paused\n      pps\n      totalValueLocked\n      lastTimeHarvested\n      totalProfits\n      ... on VaultDualsided {\n        strategy {\n          lpToken0 {\n            ...tokenData\n          }\n          lpToken1 {\n            ...tokenData\n          }\n          govFee\n          totalFee\n          poolId\n          router\n        }\n        zapper {\n          ...zapperData\n        }\n      }\n      ... on VaultStargate {\n        strategy {\n          underlyingToken {\n            ...tokenData\n          }\n          govFee\n          totalFee\n          chefId\n          poolId\n          router\n        }\n        zapper {\n          ...zapperData\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getZapper($id: ID!) {\n    zapper(id: $id) {\n      id\n      defaultDepositFee\n      silverDepositFee\n      goldDepositFee\n      smartWallet\n    }\n  }\n"
): typeof documents["\n  query getZapper($id: ID!) {\n    zapper(id: $id) {\n      id\n      defaultDepositFee\n      silverDepositFee\n      goldDepositFee\n      smartWallet\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n    query ppsVault($id: ID!, $block: Block_height) {\n      vault(id: $id, block: $block) {\n        pps\n      }\n    }\n  "
): typeof documents["\n    query ppsVault($id: ID!, $block: Block_height) {\n      vault(id: $id, block: $block) {\n        pps\n      }\n    }\n  "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<
  TDocumentNode extends DocumentNode<any, any>
> = TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
