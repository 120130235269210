import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 13 12"
    {...props}
  >
    <path
      fill="currentColor"
      d="m.5 6 1.058 1.058L5.75 2.872V12h1.5V2.873l4.193 4.184L12.5 6l-6-6-6 6Z"
    />
  </svg>
);
export default SvgArrowUpIcon;
