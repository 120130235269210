import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowDropdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#arrow-dropdown_svg__a)">
      <path fill="currentColor" d="m3 6 5 5 5-5H3Z" />
    </g>
    <defs>
      <clipPath id="arrow-dropdown_svg__a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowDropdown;
