import * as React from "react";
import type { SVGProps } from "react";
const SvgLockMoneyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 23 33"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#lock-money-icon_svg__a)">
      <path d="M20.235 14.202c-.01-.216-.03-.46-.032-.702-.004-1.714.025-3.428-.01-5.142C20.097 3.748 16.217.011 11.546 0 6.755-.01 2.866 3.747 2.8 8.474c-.026 1.888-.005 3.777-.005 5.697-4.455 5.45-3.19 12.231 1.029 15.968 4.285 3.796 10.925 3.815 15.27.059 4.217-3.645 5.63-10.486 1.139-15.996h.002Zm-7.249 13.454c0 .427.02.908-.004 1.387-.045.859-.558 1.35-1.412 1.386-.879.036-1.481-.487-1.539-1.372-.03-.45-.004-.903-.004-1.388-3.483-1.374-5.19-4.033-4.605-7.01.584-2.968 3.057-5.029 6.061-5.05 2.968-.02 5.495 2.067 6.082 5.023.596 3.006-1.094 5.659-4.579 7.026v-.002Zm4.943-15.49c-2.067-1.159-4.128-1.869-6.397-1.867-2.29.002-4.383.66-6.393 1.855 0-1.651-.196-3.212.04-4.705.512-3.225 3.513-5.48 6.917-5.195 3.191.269 5.763 2.94 5.829 6.16.026 1.186.004 2.373.004 3.755v-.003Z" />
      <path d="M11.992 21.355v-1.807c.66.126 1.111.496 1.111 1.262h1.269c0-1.136-.698-2.176-2.382-2.369v-1.37h-.837v1.351c-1.575.059-2.352.69-2.352 1.748 0 1.233 1.013 1.632 2.352 2.069v2.088c-.945-.107-1.399-.525-1.399-1.584H8.486c0 1.525.698 2.514 2.667 2.68v1.37h.837v-1.34c1.969-.049 2.667-.836 2.667-1.922 0-1.273-1.426-1.777-2.667-2.176h.002Zm-.837-.271c-.817-.282-1.083-.467-1.083-.885 0-.456.423-.651 1.083-.689v1.573Zm.837 3.283V22.5c.777.244 1.398.496 1.398 1.06 0 .524-.423.776-1.398.806Z" />
    </g>
    <defs>
      <clipPath id="lock-money-icon_svg__a">
        <path fill="currentColor" d="M0 0h23v33H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLockMoneyIcon;
