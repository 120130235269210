import * as React from "react";
import type { SVGProps } from "react";
const SvgInfoAltIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <circle cx={12} cy={12} r={11.5} stroke="#FF8C8C" />
    <path
      fill="#FF8C8C"
      d="M12.151 7.992c.816 0 1.376-.464 1.376-1.216 0-.736-.56-1.216-1.376-1.216-.832 0-1.36.48-1.36 1.216 0 .752.528 1.216 1.36 1.216ZM9.095 17h6.512v-1.792h-2.272v-5.2c0-.784-.416-1.248-1.248-1.2l-2.56.16v1.792l1.84-.112v4.56H9.095V17Z"
    />
  </svg>
);
export default SvgInfoAltIcon;
