import styled from "styled-components";
import { color, space, typography, variant } from "styled-system";
import { TextProps, variants } from "./types";

export const Text = styled.p<TextProps>`
  text-transform: ${(props) => props.transform};

  ${variant({
    // TODO: align media queries with breakpoints
    variants: {
      [variants.H2]: {
        fontSize: "44px",
        fontFamily: "helvetica",
        fontWeight: "bold",
        "@media screen and (min-width: 1024px)": { fontSize: "48px" },
      },
      [variants.H4]: {
        fontSize: "28px",
        fontFamily: "helvetica",
        fontWeight: "bold",
        "@media screen and (min-width: 1024px)": { fontSize: "32px" },
      },
      [variants.H5]: {
        fontSize: "24px",
        fontFamily: "helvetica",
        fontWeight: "bold",
        "@media screen and (min-width: 1024px)": { fontSize: "28px" },
      },
      [variants.H6]: {
        fontSize: "18px",
        fontFamily: "helvetica",
        fontWeight: "bold",
        "@media screen and (min-width: 1024px)": { fontSize: "20px" },
      },
      [variants.H7]: {
        fontSize: "15px",
        fontFamily: "helvetica",
        fontWeight: "bold",
        "@media screen and (min-width: 1024px)": { fontSize: "16px" },
      },
      [variants.P1]: {
        fontSize: "22px",
        "@media screen and (min-width: 1024px)": { fontSize: "24px" },
      },
      [variants.P2]: {
        fontSize: "16px",
        "@media screen and (min-width: 1024px)": { fontSize: "18px" },
      },
      [variants.P3]: {
        fontSize: "14px",
        "@media screen and (min-width: 1024px)": { fontSize: "16px" },
      },
      [variants.P4]: {
        fontSize: "13px",
        "@media screen and (min-width: 1024px)": { fontSize: "14px" },
        "@media screen and (min-width: 1600px)": { fontSize: "15px" },
      },
      [variants.P5]: {
        fontSize: "10px",
        "@media screen and (min-width: 1024px)": { fontSize: "12px" },
      },
    },
  })}

  ${space}
  ${color}
  ${typography}
`;

Text.defaultProps = {
  variant: variants.P3,
};

export const TextEllipsis = styled(Text)<{ lineClamp?: number }>`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.lineClamp ? props.lineClamp : 3)};
  line-clamp: ${(props) => (props.lineClamp ? props.lineClamp : 3)};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
