import { miroLockerABI } from "abis/miro";
import { CONTRACT_ADDRESSES } from "config/constants";
import { MiroTier } from "features/vaults/types";
import { formatEther } from "viem";
import { useAccount, useContractRead } from "wagmi";

const chainId = 56;

export const useMiroTier = () => {
  const { address, isConnected } = useAccount();

  const miroTierQuery = useContractRead({
    abi: miroLockerABI,
    address: CONTRACT_ADDRESSES[chainId].miroLocker,
    functionName: "getTier",
    args: [address!],
    chainId,
    select(data): MiroTier {
      const tierNumber = Number(formatEther(data));
      if (tierNumber === 1) return "gold";
      if (tierNumber === 2) return "silver";
      return "bronze";
    },
    enabled: isConnected,
  });

  // not sure what this copying over might break or decouple
  return {
    ...miroTierQuery,
    // default tier is bronze, if data is undefined
    data: miroTierQuery.data ?? "bronze",
  };
};
