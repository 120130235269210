import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --sidebar-width: 220px;
    --header-height: 80px ;
  }

  *, *::before, *::after {
    box-sizing: inherit;
    font-family: inherit;
  }

  html {
    box-sizing: border-box;
    color-scheme: ${(props) => (props.theme.isDark ? "dark" : "light")};
  }

  body {
    background-color: ${(props) => props.theme.colors.background};
    background-image: ${(props) => props.theme.colors.backgroundGradient};
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.helvetica};
    color: ${(props) => props.theme.colors.text};
  }

  img {
    height: auto;
    max-width: 100%;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    margin: 0;
  }

  a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
  }

  .icon-badge {
    --badge-size: 12px;
    display: inline-block;
    width: var(--badge-size);
    height: var(--badge-size);
    border-radius: 50%;

    &.success {
      background-color: ${(props) => "#009393"}
    }
  }

  .g__zapout-tokens {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    .and-text {
      font-size: 20px;
    }
  }

  .g__page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  #walletconnect-qrcode-modal {
    display: grid;
  }
`;
